/** @jsx jsx */
import { jsx } from "theme-ui"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Container from "../components/container"

const equipePelleas = [
  {
    title: "Producteurs",
    equipe: ["Philippe Martin", "David Thion"],
  },
  {
    title: "Business Affairs et ventes TV",
    equipe: ["Estelle Jaugin"],
  },
  {
    title: "Chargée de postproduction",
    equipe: ["Juliette Mallon"],
  },
  {
    title: "Assistante de production",
    equipe: ["Lola Hurel"],
  },
  {
    title: "Juriste",
    equipe: ["Lucas Darmon"],
  },
  {
    title: "Administratrice",
    equipe: ["Mathilde Boisgontier"],
  },
  {
    title: "Accueil",
    equipe: ["Jessica Obela"],
  },
  {
    title: "Comptable",
    equipe: ["Siham Siati"],
  },
]

const equipeBalthus = [
  {
    title: "Création et stratégie numérique",
    equipe: ["Philippe Martin", "Stelio Tzonis", "Dimitri Krassoulia-Vronsky"],
  },
  {
    title: "Chargée de développement de projets numériques",
    equipe: ["Constance Lescouarnec"],
  },
  {
    title: "Chargé des diffusions",
    equipe: ["Marco Tulio Pires"],
  },
  {
    title: "Administrateur",
    equipe: ["Benjamin Pasquier"],
  },
]

const BuyButton = ({ title, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        textDecoration: "none",
        color: "#000",
        border: "solid 1px ",
        borderRadius: "30px",
        padding: "1px 8px 2px 8px",
        display: "inline",
        opacity: 0.25,
        mr: 1,
        fontWeight: 500,
        fontSize: "12px",
        ":hover": {
          opacity: 0.7,
          cursor: "pointer",
        },
      }}
    >
      {title}
    </a>
  )
}

const Equipe = ({ equipe }) => (
  <div
    sx={{
      margin: "0 auto",
      width: ["100%", "85%", "70%"],
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    }}
  >
    {equipe.map(groupe => (
      <div
        sx={{
          width: ["100%", "50%", "30%"],
          my: 3,
          textAlign: ["center", "left", "left"],
        }}
      >
        <strong>{groupe.title}</strong>
        <div>
          {groupe.equipe.map(presonne => (
            <p>{presonne}</p>
          ))}
        </div>
      </div>
    ))}
  </div>
)

const IndexPage = ({ data, location }) => (
  <Layout>
    <SEO title="Contacts" location={location} contact={true} />
    <Section.Title1 sx={{ mt: 5 }}>CONTACT</Section.Title1>
    <Section isGrey={false} sx={{ py: 5 }}>
      <Container isFull={false} isText={true}>
        <div
          sx={{
            margin: "0 auto",
            width: ["100%", "85%", "70%"],
          }}
        >
          <p sx={{ textAlign: "justify", mb: 3 }}>
            Les Films Pelléas est une société de production cinématographique
            fondée par Philippe Martin en mars 1990.
          </p>
          <p sx={{ textAlign: "justify", mb: 3 }}>
            Depuis 30 ans, les Films Pelléas a produit plus de 80
            longs-métrages.
          </p>
          <p sx={{ textAlign: "justify", mb: 3 }}>
            La société regroupe aujourd’hui trois producteurs : Philippe Martin,
            David Thion et Dimitri Krassoulia-Vronsky. Depuis 2016, Les Films
            Pelléas assure également la direction artistique de la 3e Scène,
            plateforme numérique de l’Opéra de Paris. Balthus, filiale des Films
            Pelléas, est en charge de la production exécutive des contenus de la
            3e Scène, tout en expérimentant des stratégies digitales adaptées au
            monde culturel.
          </p>
          <p sx={{ textAlign: "justify", mb: 3 }}>
            Philippe Martin est l’auteur de deux livres d’entretiens : « Mag
            Bodard, portrait d’une productrice » (éditions La Tour Verte) et «
            Pierre Chevalier, l’homme des possibles » (Arte éditions/Séguier).
          </p>
        </div>
      </Container>
    </Section>

    <Section isGrey={true} sx={{ py: 5 }}>
      <Container isFull={false}>
        <Section.Title1 sx={{ marginBottom: "1.5em" }}>LIVRES</Section.Title1>
        <div
          sx={{
            margin: "0 auto",
            width: ["100%", "85%", "70%"],
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            sx={{ display: "flex", width: ["100%", "48%", "49%"], my: ["3"] }}
          >
            <div sx={{ width: "160px" }}>
              <Img fluid={data.bodard.childImageSharp.fluid} />
            </div>
            <div sx={{ ml: 3 }}>
              <strong>Mag Bodard : portrait d'une productrice</strong>
              <p>Philippe Martin</p>
              <p sx={{ fontSize: "0.8em", color: "#888" }}>
                2013, La Tour Verte
              </p>
              <p sx={{ mb: 1, fontSize: "0.8em", color: "#888" }}>227 pages</p>
              <BuyButton
                title={"lalibrairie"}
                url={
                  "https://www.lalibrairie.com/livres/mag-bodard--portrait-d-une-productrice_0-1655885_9782917819234.html"
                }
              />
              <BuyButton
                title={"fnac"}
                url={
                  "https://livre.fnac.com/a5843879/Philippe-Martin-Mag-Bodard-portrait-d-une-productrice"
                }
              />
              <BuyButton
                title={"amazon"}
                url={"https://www.amazon.fr/dp/2917819235"}
              />
              {/* <BuyButton
                title={"rakuten"}
                url={
                  "https://fr.shopping.rakuten.com/offer/buy/200554405/mag-bodard-portrait-d-une-productrice-de-philippe-martin.html"
                }
              /> */}
            </div>
          </div>
          <div
            sx={{ display: "flex", width: ["100%", "48%", "49%"], my: ["3"] }}
          >
            <div sx={{ width: ["207px", "190px", "190px"] }}>
              <Img fluid={data.chevalier.childImageSharp.fluid} />
            </div>
            <div sx={{ ml: 3 }}>
              <strong>
                Pierre chevalier, l'homme des possibles - entretiens
              </strong>
              <p>Philippe Martin</p>
              <p sx={{ fontSize: "0.8em", color: "#888" }}>
                2017, Séguier - Arte Édition
              </p>
              <p sx={{ mb: 1, fontSize: "0.8em", color: "#888" }}>220 pages</p>
              <BuyButton
                title={"lalibrairie"}
                url={
                  "https://www.lalibrairie.com/livres/pierre-chevalier--l-homme-des-possibles-entretiens-avec-philippe-martin_0-3758471_9782840497301.html"
                }
              />
              <BuyButton
                title={"fnac"}
                url={
                  "https://livre.fnac.com/a10215864/Philippe-Martin-Pierre-chevalier-l-homme-des-possibles-entretiens"
                }
              />
              <BuyButton
                title={"amazon"}
                url={
                  "https://www.amazon.fr/Pierre-Chevalier-Entretiens-Philippe-Martin/dp/2840497301"
                }
              />
              {/* <BuyButton
                title={"rakuten"}
                url={
                  "https://fr.shopping.rakuten.com/offer/buy/1853264643/pierre-chevalier-entretiens-de-collectif.html"
                }
              /> */}
            </div>
          </div>
        </div>
      </Container>
    </Section>

    <Section isGrey={false} sx={{ py: 5 }}>
      <Container isFull={false}>
        <Section.Title1 sx={{ mb: "1em" }}>L'ÉQUIPE</Section.Title1>
        <Section.Title2 sx={{ mb: 2 }}>Les Films Pelléas</Section.Title2>
        <Equipe equipe={equipePelleas} />
        <Section.Title2 sx={{ mb: 2, mt: 4 }}>Balthus</Section.Title2>
        <Equipe equipe={equipeBalthus} />
      </Container>
    </Section>

    <Section isGrey={true} sx={{ py: 5 }}>
      <Container isFull={false} isText={true}>
        <Section.Title1 sx={{ marginBottom: "1.3em" }}>
          ACCUEIL DES PROJETS
        </Section.Title1>
        <div
          sx={{
            margin: "0 auto",
            width: ["100%", "85%", "70%"],
          }}
        >
          <p sx={{ textAlign: "justify", mb: 3 }}>
            Si vous souhaitez nous soumettre un projet, merci de bien vouloir
            envoyer un synopsis, une note d’intention ainsi qu’un CV par mail à
            l'adresse{" "}
            <strong>
              <a
                href="mailto:developpement@pelleas.fr"
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                developpement@pelleas.fr
              </a>
            </strong>{" "}
            ou au format papier à l'adresse ci-dessous.
          </p>
          <p sx={{ textAlign: "justify", my: 3 }}>
            Nous ne manquerons pas de revenir vers vous si le projet nous
            intéresse.
          </p>
        </div>
      </Container>
    </Section>

    <Section isGrey={false} sx={{ py: 5 }}>
      <div
        sx={{
          margin: "0 auto",
          width: ["100%", "85%", "70%"],
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Container
          isFull={false}
          isText={true}
          sx={{ mb: 3, width: ["100%", "100%", "40%"] }}
        >
          <p>
            <strong>Les Films Pelléas</strong>
            <br />
            <a
              href="mailto:lesfilmspelleas@pelleas.fr"
              sx={{ textDecoration: "none", color: "inherit" }}
            >
              lesfilmspelleas@pelleas.fr
            </a>
            <br />
            25 rue Michel Le Comte 75003 Paris
            <br />
            +33 1 42 74 31 00
            <br />
          </p>
        </Container>

        <iframe
          title={"Les films Pelléas : 25 rue Michel Le Comte 75003 Paris"}
          // width={["400"]}
          sx={{ width: ["100%", "100%", "58%"] }}
          height={"250"}
          frameborder={"0"}
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=les%20films%20pelleas&key=AIzaSyARgcnzUrEOOggQFBvSs-U202e3nQbsvLQ"
          allowfullscreen
        ></iframe>
      </div>
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    bodard: file(
      relativePath: { eq: "Mag-Bodard-portrait-d-une-productrice.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    chevalier: file(
      relativePath: { eq: "Pierre-Chevalier-l-homme-des-poibles.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default IndexPage
